import { defineStore } from "pinia";

import type { TableRow } from "@/types/table";
import {
  getKpiAdsEfficiencies as getKpiAdsEfficienciesApi,
  getChartAdsEfficiencies as getChartAdsEfficienciesApi,
  getKpiTotalAdsEfficiencies as getKpiTotalAdsEfficienciesApi,
  getAdsEfficienciesTable as getAdsEfficienciesTableApi,
} from "@/api/adsSummary";
import type { RequestError, requestParams } from "@/types/requests";
import type { EfficienciesStore } from "@/types/adsSummary/adsEfficiencies";
import {
  getAbortSignal,
  replaceController,
  replaceSingleController,
} from "@/utils/createAbortControllers";

const controllers: AbortController[] = [];
let tableController = new AbortController();

export const useAdsSummaryEfficienciesStore = defineStore(
  "adsSummaryEfficiencies",
  {
    state: (): EfficienciesStore => ({
      filters: {
        granularity: "MONTH",
      },
      adsEfficienciesTable: {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      },
      chartAdsEfficiencies: [],
      adsEfficienciesTotalValues: null,
      adsEfficienciesKpiValues: null,
      WoWKpiData: null,
      MoMKpiData: null,
      YoYKpiData: null,
      PoPKpiData: null,
      isAdsEfficienciesLoader: true,
      isAdsEfficienciesKpiLoader: true,
      isAdsEfficienciesTableLoader: true,
      isAdsEfficienciesChartLoader: true,
      isAdsEfficienciesTableTotalLoader: true,
    }),

    actions: {
      abortRequestsEfficienciesStore() {
        this.cleanStore();
        controllers.forEach((controller: AbortController, index: number) => {
          replaceController(index, controllers);
        });
      },

      abortTableRequest() {
        tableController = replaceSingleController(tableController);
      },

      getChartAdsEfficiencies(params: requestParams) {
        this.isAdsEfficienciesLoader = true;
        this.isAdsEfficienciesChartLoader = true;
        getChartAdsEfficienciesApi(params, {
          signal: getAbortSignal(controllers),
        })
          .then(({ data }) => {
            this.chartAdsEfficiencies = data;
          })
          .catch((e) => {})
          .finally(() => {
            this.isAdsEfficienciesLoader = false;
            this.isAdsEfficienciesChartLoader = false;
          });
      },

      getKpiAdsEfficiencies(
        params: requestParams,
        type?: "WoWKpiData" | "MoMKpiData" | "YoYKpiData" | "PoPKpiData",
      ) {
        return getKpiAdsEfficienciesApi(params, {
          signal: getAbortSignal(controllers),
        })
          .then(({ data }) => {
            if (type) {
              this[type] = null;
              this[type] = data;
            } else {
              return (this.adsEfficienciesKpiValues = data);
            }
          })
          .catch((e) => {});
      },

      getKpiTotalAdsEfficiencies(params: requestParams) {
        return getKpiTotalAdsEfficienciesApi(params, {
          signal: getAbortSignal(controllers),
        })
          .then(({ data }) => {
            this.adsEfficienciesTotalValues = data;
          })
          .catch((e) => {});
      },

      updateKpisLoader(value: boolean) {
        this.isAdsEfficienciesKpiLoader = value;
      },

      getAdsEfficienciesTable(
        params: requestParams,
        callback?: (data: TableRow[]) => void,
      ) {
        if (params.limit === 0) {
          this.isAdsEfficienciesTableTotalLoader = true;
        } else {
          this.isAdsEfficienciesTableLoader = true;
        }
        getAdsEfficienciesTableApi(params, {
          signal: tableController.signal,
        })
          .then(({ data }) => {
            if (params.limit === 0) {
              callback && callback(data.data);
            } else {
              this.adsEfficienciesTable.data = data.data;
              this.adsEfficienciesTable.pagination = data.pagination;
            }
            this.isAdsEfficienciesTableLoader = false;
            this.isAdsEfficienciesTableTotalLoader = false;
          })
          .catch((err: RequestError) => {
            const errorData = JSON.parse(err?.message);
            this.isAdsEfficienciesTableLoader = false;
            this.isAdsEfficienciesTableTotalLoader = false;
            if (errorData.status === "canceled") {
              this.isAdsEfficienciesTableLoader = true;
            }
          });
      },

      cleanStore() {
        this.adsEfficienciesTable = {
          pagination: {
            totalPages: 1,
            currentPage: 1,
            pageSizeNumber: 10,
          },
          data: [],
        };
        this.chartAdsEfficiencies = [];
        this.adsEfficienciesTotalValues = null;
        this.adsEfficienciesKpiValues = null;
        this.WoWKpiData = null;
        this.MoMKpiData = null;
        this.YoYKpiData = null;
        this.PoPKpiData = null;
        this.isAdsEfficienciesLoader = true;
        this.isAdsEfficienciesKpiLoader = true;
        this.isAdsEfficienciesTableLoader = true;
        this.isAdsEfficienciesChartLoader = true;
        this.isAdsEfficienciesTableTotalLoader = true;
      },
    },
    getters: {
      clickThroughRateKpiValue() {
        const values: {
          name: string;
          value: { difference: number; ratio_percentage: number } | undefined;
        }[] = [
          { name: "PoP", value: this.PoPKpiData?.ClickThroughRate },
          { name: "WoW", value: this.WoWKpiData?.ClickThroughRate },
          { name: "MoM", value: this.MoMKpiData?.ClickThroughRate },
          { name: "YoY", value: this.YoYKpiData?.ClickThroughRate },
        ];
        return {
          name: "ClickThroughRate",
          values: values,
        };
      },
      conversionKpiValue() {
        const values: {
          name: string;
          value: { difference: number; ratio_percentage: number } | undefined;
        }[] = [
          { name: "PoP", value: this.PoPKpiData?.Conversion },
          { name: "WoW", value: this.WoWKpiData?.Conversion },
          { name: "MoM", value: this.MoMKpiData?.Conversion },
          { name: "YoY", value: this.YoYKpiData?.Conversion },
        ];
        return {
          name: "Conversion",
          values: values,
        };
      },
      costPerClickKpiValue() {
        const values: {
          name: string;
          value: { difference: number; ratio_percentage: number } | undefined;
        }[] = [
          { name: "PoP", value: this.PoPKpiData?.CostPerClick },
          { name: "WoW", value: this.WoWKpiData?.CostPerClick },
          { name: "MoM", value: this.MoMKpiData?.CostPerClick },
          { name: "YoY", value: this.YoYKpiData?.CostPerClick },
        ];
        return {
          name: "CostPerClick",
          values: values,
        };
      },
    },
  },
);
