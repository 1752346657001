import type { RouteRecordRaw } from "vue-router";

import auth from "./auth";
import { RouteNames } from "@/types/router";
import { coreRoutes, additionalRoutes } from "./core";

const modules: Array<RouteRecordRaw> = [
  {
    path: "/:pathMatch(.*)*",
    redirect: "/not-found",
  },

  {
    path: "/not-found",
    name: RouteNames.NOT_FOUND,
    component: () => import("@/views/NotFound.vue"),
  },
  {
    path: "/dont-have-permission",
    name: RouteNames.DONT_HAVE_PERMISSION,
    component: () => import("@/views/AccessDeniedPage.vue"),
  },
  {
    path: "/password-change",
    name: RouteNames.PASSWORD_CHANGE,
    component: () => import("@/views/PasswordChange.vue"),
  },

  {
    path: "/",
    redirect: "/insightful",
  },
  ...auth,
  ...coreRoutes,
  ...additionalRoutes,
];

export default modules;
