import { defineStore } from "pinia";

import type { TableRow } from "@/types/table";
import {
  getChartAdsPerformance as getChartAdsPerformanceApi,
  getKpiAdsPerformance as getKpiAdsPerformanceApi,
  getKpiTotalAdsPerformance as getKpiTotalAdsPerformanceApi,
  getAdsPerformanceTable as getAdsPerformanceTableApi,
} from "@/api/adsSummary";
import type { RequestError, requestParams } from "@/types/requests";
import type { PerformanseStore } from "@/types/adsSummary/adsPerformance";
import {
  getAbortSignal,
  replaceController,
  replaceSingleController,
} from "@/utils/createAbortControllers";

const controllers: AbortController[] = [];
let tableController = new AbortController();

export const useAdsSummaryPerformanceStore = defineStore(
  "adsSummaryPerformance",
  {
    state: (): PerformanseStore => ({
      filters: {
        granularity: "MONTH",
      },
      periodValues: [],
      chartAdsPerformance: [],
      adsPerformanceTable: {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      },
      adsPerformanceTotalValues: null,
      adsPerformanceKpiValues: null,
      WoWKpiData: null,
      MoMKpiData: null,
      YoYKpiData: null,
      PoPKpiData: null,
      isAdsPerformanceTable: true,
      isAdsPerformanceLoader: true,
      isAdsPerformanceKpiLoader: true,
      isAdsPerformanceTableTotal: true,
      isAdsPerformanceChartLoader: true,
    }),

    actions: {
      abortRequestsPerformanceStore() {
        this.cleanStore();
        controllers.forEach((controller: AbortController, index: number) => {
          replaceController(index, controllers);
        });
      },

      abortTableRequest() {
        tableController = replaceSingleController(tableController);
      },

      getChartAdsPerformance(params: requestParams) {
        this.isAdsPerformanceLoader = true;
        this.isAdsPerformanceChartLoader = true;
        getChartAdsPerformanceApi(params, {
          signal: getAbortSignal(controllers),
        })
          .then(({ data }) => {
            this.chartAdsPerformance = data;
          })
          .catch((e) => {})
          .finally(() => {
            this.isAdsPerformanceLoader = false;
            this.isAdsPerformanceChartLoader = false;
          });
      },

      getKpiAdsPerformance(
        params: requestParams,
        type?: "WoWKpiData" | "MoMKpiData" | "YoYKpiData" | "PoPKpiData",
      ) {
        return getKpiAdsPerformanceApi(params, {
          signal: getAbortSignal(controllers),
        })
          .then(({ data }) => {
            if (type) {
              this[type] = null;
              this[type] = data;
            } else {
              this.adsPerformanceKpiValues = null;
              return (this.adsPerformanceKpiValues = data);
            }
          })
          .catch((e) => {});
      },

      getKpiTotalAdsPerformance(params: requestParams) {
        return getKpiTotalAdsPerformanceApi(params, {
          signal: getAbortSignal(controllers),
        })
          .then(({ data }) => {
            this.adsPerformanceTotalValues = data;
          })
          .catch((e) => {});
      },

      updateKpiLoader(value: boolean) {
        this.isAdsPerformanceKpiLoader = value;
      },

      getAdsPerformanceTable(
        params: requestParams,
        callback?: (data: TableRow[]) => void,
      ) {
        if (params.limit === 0) {
          this.isAdsPerformanceTableTotal = true;
        } else {
          this.isAdsPerformanceTable = true;
        }
        getAdsPerformanceTableApi(params, {
          signal: tableController.signal,
        })
          .then(({ data }) => {
            if (params.limit === 0) {
              callback && callback(data.data);
            } else {
              this.adsPerformanceTable.data = data.data;
              this.adsPerformanceTable.pagination = data.pagination;
            }
            this.isAdsPerformanceTable = false;
            this.isAdsPerformanceTableTotal = false;
          })
          .catch((err: RequestError) => {
            const errorData = JSON.parse(err?.message);
            this.isAdsPerformanceTable = false;
            this.isAdsPerformanceTableTotal = false;
            if (errorData.status === "canceled") {
              this.isAdsPerformanceTable = true;
            }
          });
      },

      updateGranularity(payload: "MONTH" | "WEEK") {
        this.filters.granularity = payload;
      },

      putPeriodValues(value: string[]) {
        this.periodValues = value;
      },

      cleanStore() {
        this.filters.granularity = "MONTH";
        this.periodValues = [];
        this.chartAdsPerformance = [];
        this.adsPerformanceTable = {
          pagination: {
            totalPages: 1,
            currentPage: 1,
            pageSizeNumber: 10,
          },
          data: [],
        };
        this.adsPerformanceTotalValues = null;
        this.adsPerformanceKpiValues = null;
        this.WoWKpiData = null;
        this.MoMKpiData = null;
        this.YoYKpiData = null;
        this.PoPKpiData = null;
        this.isAdsPerformanceTable = true;
        this.isAdsPerformanceLoader = true;
        this.isAdsPerformanceKpiLoader = true;
        this.isAdsPerformanceTableTotal = true;
        this.isAdsPerformanceChartLoader = true;
      },
    },

    getters: {
      revenueKpiValue() {
        const values: {
          name: string;
          value: { difference: number; ratio_percentage: number } | undefined;
        }[] = [
          { name: "PoP", value: this.PoPKpiData?.Revenue },
          { name: "WoW", value: this.WoWKpiData?.Revenue },
          { name: "MoM", value: this.MoMKpiData?.Revenue },
          { name: "YoY", value: this.YoYKpiData?.Revenue },
        ];

        return {
          name: "Revenue",
          values: values,
        };
      },
      spendKpiValue() {
        const values: {
          name: string;
          value: { difference: number; ratio_percentage: number } | undefined;
        }[] = [
          { name: "PoP", value: this.PoPKpiData?.Spend },
          { name: "WoW", value: this.WoWKpiData?.Spend },
          { name: "MoM", value: this.MoMKpiData?.Spend },
          { name: "YoY", value: this.YoYKpiData?.Spend },
        ];

        return {
          name: "Spend",
          values: values,
        };
      },
      roasKpiValue() {
        const values: {
          name: string;
          value: { difference: number; ratio_percentage: number } | undefined;
        }[] = [
          { name: "PoP", value: this.PoPKpiData?.["ROAS"] },
          { name: "WoW", value: this.WoWKpiData?.["ROAS"] },
          { name: "MoM", value: this.MoMKpiData?.["ROAS"] },
          { name: "YoY", value: this.YoYKpiData?.["ROAS"] },
        ];

        return {
          name: "ROAS",
          values: values,
        };
      },
    },
  },
);
