import { DateTime } from "luxon";
import { defineStore } from "pinia";

import type { TableRow } from "@/types/table";
import { useFiltersStore } from "@/store/filters";

import type { requestParams } from "@/types/requests";
import {
  getAbortSignal,
  replaceController,
  replaceSingleController,
} from "@/utils/createAbortControllers";
import type {
  BrandProtectionStore,
  LostRevenueCalculatedKPI,
} from "@/types/brandProtection";
import {
  getBrandProtectionSellersKpi as getBrandProtectionSellersKpiApi,
  getBrandProtectionSellersChart as getBrandProtectionSellersChartApi,
  getBrandProtectionSellersTable as getBrandProtectionSellersTableApi,
  getBrandProtectionSellersBySKUKpi as getBrandProtectionSellersBySKUKpiApi,
  getBrandProtectionLostRevenueChart as getBrandProtectionLostRevenueChartApi,
  getBrandProtectionLostRevenueKPI as getBrandProtectionLostRevenueKPIApi,
  getBrandProtectionLostRevenueKPITotal as getBrandProtectionLostRevenueKPITotalApi,
  getBrandProtectionLostRevenueTable as getBrandProtectionLostRevenueTableApi,
  getTableByProductApi,
  getTableBySellerApi,
} from "@/api/brandProtections";

const controllers: AbortController[] = [];
let tableLostRevenueController = new AbortController();
let tableBrandSellersController = new AbortController();
let tableWithDropdown = new AbortController();

export const useNewBrandProtectionStore = defineStore(
  "newBrandProtectionStore",
  {
    state: (): BrandProtectionStore => ({
      selectedTableOption: "BySeller",
      sellersKPI: null,
      sellerBySKUChart: {
        sellers: [],
        products: [],
      },
      sellersChart: [],
      sellersTable: {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      },
      sellersTableWithDropdown: {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      },
      revenueChart: [],
      revenueKPI: null,
      revenueKPITotal: null,
      revenueTable: {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      },
      WoWKpiData: null,
      MoMKpiData: null,
      YoYKpiData: null,
      PoPKpiData: null,
      sellersKPILoader: true,
      sellersChartLoader: true,
      sellersTableLoader: true,
      isRevenueKPILoader: true,
      isRevenueChartLoader: true,
      isRevenueTableLoader: true,
      isCollapseTableLoader: true,
      sellerBySKUChartLoader: true,
      isRevenueKPITotalLoader: true,
      sellersTableTotalLoader: true,
      isRevenueTableTotalLoader: true,
      isTableByProductTotalLoader: true,
    }),

    actions: {
      abortRequestsBrandStore() {
        this.cleanStore();
        controllers.forEach((controller: AbortController, index: number) => {
          replaceController(index, controllers);
        });
      },
      abortTableLostRevenueRequest() {
        tableLostRevenueController = replaceSingleController(
          tableLostRevenueController,
        );
      },
      abortTableBrandSellersRequest() {
        tableBrandSellersController = replaceSingleController(
          tableBrandSellersController,
        );
      },

      abortTableWithDropdownRequest() {
        tableWithDropdown = replaceSingleController(tableWithDropdown);
      },

      changeSelectedTableOption(option: "ByProduct" | "BySeller") {
        this.selectedTableOption = option;
      },

      getBrandProtectionSellersKpi(params: requestParams) {
        this.sellersKPILoader = true;
        getBrandProtectionSellersKpiApi(params, {
          signal: getAbortSignal(controllers),
        })
          .then(({ data }) => {
            this.sellersKPI = data;
          })
          .catch((e) => {})
          .finally(() => {
            this.sellersKPILoader = false;
          });
      },

      getBrandProtectionSellersChart(params: requestParams) {
        const filterStore = useFiltersStore();
        this.sellersChartLoader = true;
        getBrandProtectionSellersChartApi(params, {
          signal: getAbortSignal(controllers),
        })
          .then(({ data }) => {
            const sortedDate = data.sort((a, b) => {
              if (filterStore.granularity === "MONTH") {
                return (
                  DateTime.fromFormat(a.DATE, "yyyy-dd-MM").toMillis() -
                  DateTime.fromFormat(b.DATE, "yyyy-dd-MM").toMillis()
                );
              } else {
                return (
                  DateTime.fromFormat(a.DATE, "yyyy-dd-MM").toMillis() -
                  DateTime.fromFormat(b.DATE, "yyyy-dd-MM").toMillis()
                );
              }
            });
            this.sellersChart = sortedDate;
          })
          .catch((e) => {})
          .finally(() => {
            this.sellersChartLoader = false;
          });
      },

      getBrandProtectionSellersBySKUKpi(params: requestParams) {
        this.sellerBySKUChartLoader = true;
        getBrandProtectionSellersBySKUKpiApi(params, {
          signal: getAbortSignal(controllers),
        })
          .then(({ data }) => {
            this.sellerBySKUChart = data;
          })
          .catch((e) => {})
          .finally(() => {
            this.sellerBySKUChartLoader = false;
          });
      },

      getBrandProtectionSellersTable(
        params: requestParams,
        callback?: (data: TableRow[]) => void,
      ) {
        if (params.limit === 0) {
          this.sellersTableTotalLoader = true;
        } else {
          this.sellersTableLoader = true;
        }
        getBrandProtectionSellersTableApi(params, {
          signal: tableBrandSellersController.signal,
        })
          .then(({ data }) => {
            const formattedData = data.data.map((el) => {
              const obj: { [key: string]: string | number } = {};
              for (const key in el) {
                el[key] === null ? (obj[key] = "") : (obj[key] = el[key]);
              }
              return obj;
            });

            if (params.limit === 0) {
              callback && callback(formattedData);
            } else {
              this.sellersTable.data = formattedData;
              this.sellersTable.pagination = data.pagination;
            }
            this.sellersTableLoader = false;
            this.sellersTableTotalLoader = false;
          })
          .catch((err: any) => {
            const errorData = JSON.parse(err?.message);
            this.sellersTableLoader = false;
            this.sellersTableTotalLoader = false;
            if (errorData.status === "canceled") {
              this.sellersTableLoader = true;
            }
          });
      },

      getBrandProtectionLostRevenueKPI(
        params: requestParams,
        type?: "WoWKpiData" | "MoMKpiData" | "YoYKpiData" | "PoPKpiData",
      ) {
        return getBrandProtectionLostRevenueKPIApi(params, {
          signal: getAbortSignal(controllers),
        })
          .then(({ data }) => {
            if (type) {
              this[type] = null;
              this[type] = data;
            } else {
              this.revenueKPI = data;
            }
          })
          .catch((e) => {})
          .finally(() => {});
      },

      getBrandProtectionLostRevenueKPITotal(params: requestParams) {
        getBrandProtectionLostRevenueKPITotalApi(params, {
          signal: getAbortSignal(controllers),
        })
          .then(({ data }) => {
            this.revenueKPITotal = data;
          })
          .catch((e) => {})
          .finally(() => {});
      },

      updateKpisLoader(value: boolean) {
        this.isRevenueKPILoader = value;
      },

      getBrandProtectionLostRevenueChart(params: requestParams) {
        const filterStore = useFiltersStore();
        this.isRevenueChartLoader = true;

        getBrandProtectionLostRevenueChartApi(params, {
          signal: getAbortSignal(controllers),
        })
          .then(({ data }) => {
            const sortedDate = data.sort((a, b) => {
              if (filterStore.granularity === "MONTH") {
                return (
                  DateTime.fromFormat(a.DATE, "yyyy-dd-MM").toMillis() -
                  DateTime.fromFormat(b.DATE, "yyyy-dd-MM").toMillis()
                );
              } else {
                return (
                  DateTime.fromISO(a.DATE).toMillis() -
                  DateTime.fromISO(b.DATE).toMillis()
                );
              }
            });
            this.revenueChart = sortedDate;
          })
          .catch((e) => {})
          .finally(() => {
            this.isRevenueChartLoader = false;
          });
      },

      getBrandProtectionLostRevenueTable(
        params: requestParams,
        callback?: (data: TableRow[]) => void,
      ) {
        if (params.limit === 0) {
          this.isRevenueTableTotalLoader = true;
        } else {
          this.isRevenueTableLoader = true;
        }
        getBrandProtectionLostRevenueTableApi(params, {
          signal: tableLostRevenueController.signal,
        })
          .then(({ data }) => {
            if (params.limit === 0) {
              callback && callback(data.data);
            } else {
              this.revenueTable.data = data.data;
              this.revenueTable.pagination = data.pagination;
            }
            this.isRevenueTableLoader = false;
            this.isRevenueTableTotalLoader = false;
          })
          .catch((err: any) => {
            const errorData = JSON.parse(err?.message);
            this.isRevenueTableLoader = false;
            this.isRevenueTableTotalLoader = false;
            if (errorData.status === "canceled") {
              this.isRevenueTableLoader = true;
            }
          });
      },

      async getTableByProduct(
        params: requestParams,
        callback?: (data: TableRow[]) => void,
      ) {
        if (params.limit === 0) {
          this.isTableByProductTotalLoader = true;
        } else {
          this.isCollapseTableLoader = true;
        }
        try {
          const { data } = await getTableByProductApi(params, {
            signal: tableWithDropdown.signal,
          });
          if (params.limit === 0) {
            const arr = [];
            for (let i = 0; i <= data.data.length - 1; i++) {
              for (let j = 0; j <= data.data[i]?.SELLERS?.length - 1; j++) {
                arr.push({
                  NAME: data.data[i].PRODUCT_NAME,
                  ASIN: data.data[i]?.ASIN,
                  SKU: data.data[i]?.SKU,
                  CHANNEL_NAME: data.data[i]?.SELLERS?.[j].CHANNEL_NAME,
                  ACTIVE_INVENTORY: data.data[i]?.SELLERS?.[j].ACTIVE_INVENTORY,
                  PRODUCT_NAME: data.data[i]?.SELLERS?.[j].NAME,
                });
              }
            }
            callback && callback(arr);
          } else {
            this.sellersTableWithDropdown.data = data.data;
            this.sellersTableWithDropdown.pagination = data.pagination;
          }
          this.isCollapseTableLoader = false;
          this.isTableByProductTotalLoader = false;
        } catch (err) {
          const error = err as Error;
          const errorData = JSON.parse(error?.message);
          this.isCollapseTableLoader = false;
          this.isTableByProductTotalLoader = false;
          if (errorData.status === "canceled") {
            this.isCollapseTableLoader = true;
          }
        }
      },

      async getTableBySellers(
        params: requestParams,
        callback?: (data: TableRow[]) => void,
      ) {
        if (params.limit === 0) {
          this.isTableByProductTotalLoader = true;
        } else {
          this.isCollapseTableLoader = true;
        }
        try {
          const { data } = await getTableBySellerApi(params, {
            signal: tableWithDropdown.signal,
          });
          if (params.limit === 0) {
            const arr = [];
            for (let i = 0; i <= data.data.length - 1; i++) {
              for (let j = 0; j <= data.data[i]?.PRODUCTS?.length - 1; j++) {
                arr.push({
                  NAME: data.data[i].SELLER_NAME,
                  CHANNEL_NAME: data.data[i].CHANNEL_NAME,
                  ACTIVE_INVENTORY:
                    data.data[i]?.PRODUCTS?.[j].ACTIVE_INVENTORY,
                  ASIN: data.data[i]?.PRODUCTS?.[j].ASIN,
                  SKU: data.data[i]?.PRODUCTS?.[j].SKU,
                  PRODUCT_NAME: data.data[i]?.PRODUCTS?.[j].NAME,
                });
              }
            }
            callback && callback(arr);
          } else {
            this.sellersTableWithDropdown.data = data.data;
            this.sellersTableWithDropdown.pagination = data.pagination;
          }
          this.isCollapseTableLoader = false;
          this.isTableByProductTotalLoader = false;
        } catch (err: any) {
          const error = err as Error;
          const errorData = JSON.parse(error?.message);
          this.isCollapseTableLoader = false;
          this.isTableByProductTotalLoader = false;
          if (errorData.status === "canceled") {
            this.isCollapseTableLoader = true;
          }
        }
      },

      cleanStore() {
        this.sellersKPI = null;
        this.sellerBySKUChart = {
          sellers: [],
          products: [],
        };
        this.sellersChart = [];
        this.sellersTable = {
          pagination: {
            totalPages: 1,
            currentPage: 1,
            pageSizeNumber: 10,
          },
          data: [],
        };
        this.revenueChart = [];
        this.revenueKPI = null;
        this.revenueKPITotal = null;
        this.revenueTable = {
          pagination: {
            totalPages: 1,
            currentPage: 1,
            pageSizeNumber: 10,
          },
          data: [],
        };
        this.WoWKpiData = null;
        this.MoMKpiData = null;
        this.YoYKpiData = null;
        this.PoPKpiData = null;
        this.sellersKPILoader = true;
        this.sellersChartLoader = true;
        this.sellersTableLoader = true;
        this.isRevenueKPILoader = true;
        this.isRevenueChartLoader = true;
        this.isRevenueTableLoader = true;
        this.isCollapseTableLoader = true;
        this.isRevenueKPITotalLoader = true;
        this.sellersTableTotalLoader = true;
        this.isRevenueTableTotalLoader = true;
        this.isTableByProductTotalLoader = true;
      },
    },

    getters: {
      chartsLoading() {
        if (this.sellersChartLoader) {
          return true;
        } else return false;
      },

      lostRevenueKpiValue(): LostRevenueCalculatedKPI {
        const values = [
          {
            name: "PoP",
            value: {
              difference: this.PoPKpiData?.[`LOST_REVENUE_DIFFERENCE`] || "",
              ratio_percentage:
                this.PoPKpiData?.[`LOST_REVENUE_RATIO_PERCENTAGE`] || "",
            },
          },
          {
            name: "WoW",
            value: {
              difference: this.WoWKpiData?.[`LOST_REVENUE_DIFFERENCE`] || "",
              ratio_percentage:
                this.WoWKpiData?.[`LOST_REVENUE_RATIO_PERCENTAGE`] || "",
            },
          },
          {
            name: "MoM",
            value: {
              difference: this.MoMKpiData?.[`LOST_REVENUE_DIFFERENCE`] || "",
              ratio_percentage:
                this.MoMKpiData?.[`LOST_REVENUE_RATIO_PERCENTAGE`] || "",
            },
          },
          {
            name: "YoY",
            value: {
              difference: this.YoYKpiData?.[`LOST_REVENUE_DIFFERENCE`] || "",
              ratio_percentage:
                this.YoYKpiData?.[`LOST_REVENUE_RATIO_PERCENTAGE`] || "",
            },
          },
        ];

        return {
          name: "Lost Revenue",
          values: values,
        };
      },
      byBoxKpiValue(): LostRevenueCalculatedKPI {
        const values = [
          {
            name: "PoP",
            value: {
              difference: this.PoPKpiData?.[`BUY_BOX_DIFFERENCE`] || "",
              ratio_percentage:
                this.PoPKpiData?.[`BUY_BOX_RATIO_PERCENTAGE`] || "",
            },
          },
          {
            name: "WoW",
            value: {
              difference: this.WoWKpiData?.[`BUY_BOX_DIFFERENCE`] || "",
              ratio_percentage:
                this.WoWKpiData?.[`BUY_BOX_RATIO_PERCENTAGE`] || "",
            },
          },
          {
            name: "MoM",
            value: {
              difference: this.MoMKpiData?.[`BUY_BOX_DIFFERENCE`] || "",
              ratio_percentage:
                this.MoMKpiData?.[`BUY_BOX_RATIO_PERCENTAGE`] || "",
            },
          },
          {
            name: "YoY",
            value: {
              difference: this.YoYKpiData?.[`BUY_BOX_DIFFERENCE`] || "",
              ratio_percentage:
                this.YoYKpiData?.[`BUY_BOX_RATIO_PERCENTAGE`] || "",
            },
          },
        ];

        return {
          name: "Buy Box",
          values: values,
        };
      },

      revenueKpiValue(): LostRevenueCalculatedKPI {
        const values = [
          {
            name: "PoP",
            value: {
              difference: this.PoPKpiData?.[`REVENUE_DIFFERENCE`] || "",
              ratio_percentage:
                this.PoPKpiData?.[`REVENUE_RATIO_PERCENTAGE`] || "",
            },
          },
          {
            name: "WoW",
            value: {
              difference: this.WoWKpiData?.[`REVENUE_DIFFERENCE`] || "",
              ratio_percentage:
                this.WoWKpiData?.[`REVENUE_RATIO_PERCENTAGE`] || "",
            },
          },
          {
            name: "MoM",
            value: {
              difference: this.MoMKpiData?.[`REVENUE_DIFFERENCE`] || "",
              ratio_percentage:
                this.MoMKpiData?.[`REVENUE_RATIO_PERCENTAGE`] || "",
            },
          },
          {
            name: "YoY",
            value: {
              difference: this.YoYKpiData?.[`REVENUE_DIFFERENCE`] || "",
              ratio_percentage:
                this.YoYKpiData?.[`REVENUE_RATIO_PERCENTAGE`] || "",
            },
          },
        ];

        return {
          name: "Revenue",
          values: values,
        };
      },
    },
  },
);
