export enum Permissions {
  ADMIN = "ADMIN",
  ADS_PERFORMANCE = "report:read:ads-performance",
  ADS_SUMMARY = "report:read:ads-summary",
  BRAND_PROTECTION = "report:read:brand-protection",
  RETURNS = "report:read:returns",
  REVIEW_REPORT = "report:read:reviews-report",
  SALES_REPORT = "report:read:sales-report",
  ADS_CAMPAIGN = "report:read:campaigns-report",
  EDIT_ADS_CAMPAIGN = "report:edit:campaigns-report",
  INVENTORY = "report:read:inventory-report",
  ALERTS = "report:read:product-alerts",
  PERFORMANCE = "report:read:product-performance",
  ACCOUNT_HEALTH = "report:read:account-health",
  WAREHOUSE = "report:read:warehouse",
  CUSTOMER_ANALYTICS = "report:read:customer-analytics",
}
