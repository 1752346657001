import { DateTime } from "luxon";
import { defineStore } from "pinia";

import { useFiltersStore } from "@/store/filters";
import type { RequestError, requestParams } from "@/types/requests.ts";
import {
  getAbortSignal,
  replaceController,
  replaceSingleController,
} from "@/utils/createAbortControllers.ts";

import { IKpisFormat } from "@/types/Kpi.ts";
import type { TableHeader, TableRow } from "@/types/table.ts";
import {
  getPreppingByProductTableApi,
  getPreppingByUserTableApi,
  getPreppingChartApi,
  getPreppingKpisApi,
  getPreppingKpiTotalApi,
  getPreppingUnitsByBrandTableApi,
  getPreppingUserPerformanceTableApi,
} from "@/api/warehouse.ts";
import { IReceivingStore } from "@/types/warehouse/prepping.ts";

const controllers: AbortController[] = [];
let tableController = new AbortController();

export const usePreppingStore = defineStore("preppingStore", {
  state: (): IReceivingStore => ({
    KPITotal: null,
    WoWKpiData: null,
    MoMKpiData: null,
    YoYKpiData: null,
    PoPKpiData: null,
    preppingChart: [],
    userPerformanceTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    expandTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    unitsByBrand: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    isPreppingKpiLoader: true,
    isPreppingChartLoader: true,
    isPreppingUserPerformanceLoader: true,
    isPreppingUserPerformanceTotalLoader: true,
    isPreppingCollapseTableLoader: true,
    isPreppingCollapseTableTotalLoader: true,
    isPreppingUnitsByBrandLoader: true,
    isPreppingUnitsByBrandTotalLoader: true,
  }),
  actions: {
    abortControllersStore() {
      controllers.forEach((controller: AbortController, index: number) => {
        replaceController(index, controllers);
      });
    },

    abortTableRequest() {
      tableController = replaceSingleController(tableController);
    },

    updateKpisLoader(value: boolean) {
      this.isPreppingKpiLoader = value;
    },

    async getPreppingKpi(
      params: requestParams,
      type: "WoWKpiData" | "MoMKpiData" | "YoYKpiData" | "PoPKpiData",
    ) {
      try {
        const { data } = await getPreppingKpisApi(params, {
          signal: getAbortSignal(controllers),
        });
        this[type] = null;
        this[type] = data;
      } catch (e) {
        // eslint-disable-next-line no-console
      }
    },

    async getPreppingTotalKpi(params: requestParams) {
      try {
        const { data } = await getPreppingKpiTotalApi(params, {
          signal: getAbortSignal(controllers),
        });
        this.KPITotal = data;
      } catch (e) {
        // eslint-disable-next-line no-console
      }
    },

    async getPreppingChart(params: requestParams) {
      const filterStore = useFiltersStore();
      this.isPreppingChartLoader = true;
      try {
        const { data } = await getPreppingChartApi(params, {
          signal: getAbortSignal(controllers),
        });

        const sortedChartData = data.sort((a, b) => {
          if (filterStore.granularity === "MONTH") {
            return (
              DateTime.fromFormat(a.DATE, "yyyy-dd-MM").toMillis() -
              DateTime.fromFormat(b.DATE, "yyyy-dd-MM").toMillis()
            );
          } else {
            return (
              DateTime.fromISO(a.DATE).toMillis() -
              DateTime.fromISO(b.DATE).toMillis()
            );
          }
        });
        this.preppingChart = sortedChartData;
      } catch (e) {
        // eslint-disable-next-line no-console
      } finally {
        this.isPreppingChartLoader = false;
      }
    },

    async getPreppingUserPerformanceTable(
      params: requestParams,
      callback?: (
        data: TableRow[],
        headers: TableHeader[],
        customHeaders: boolean,
      ) => void,
    ) {
      if (params.isDownload) {
        this.isPreppingUserPerformanceTotalLoader = true;
      } else {
        this.isPreppingUserPerformanceLoader = true;
      }

      try {
        const { data }: TableRow = await getPreppingUserPerformanceTableApi(
          params,
          {
            signal: tableController.signal,
          },
        );

        if (params.isDownload) {
          const headers = [
            {
              name: "User",
              value: "USER",
            },
            {
              name: "SKU",
              value: "SKU",
            },
            {
              name: "Complexity",
              value: "COMPLEXITY",
            },
            {
              name: "Total Units",
              value: "TOTAL_UNITS",
              isNumber: true,
            },
            {
              name: "Average Time",
              value: "AVERAGE_TIME",
              roundedToTwoDecimals: true,
            },
            {
              name: "Baseline",
              value: "BASELINE",
              roundedToTwoDecimals: true,
            },
            {
              name: "Difference",
              value: "DIFF",
              roundedToTwoDecimals: true,
            },
            {
              name: "Difference %",
              value: "PERCENT_DIFF",
              roundedToTwoDecimals: true,
              dividedForXlsx: true,
              isPercentage: true,
            },
          ];
          callback && callback(data, headers, true);
        } else {
          this.userPerformanceTable.data = [
            { USER: "Baseline", ...data.baseline },
            ...data.performance.data,
          ];
          this.userPerformanceTable.pagination = data.performance.pagination;
        }

        this.isPreppingUserPerformanceLoader = false;
        this.isPreppingUserPerformanceTotalLoader = false;
      } catch (error: RequestError) {
        const errorData = JSON.parse(error?.message);
        this.isPreppingUserPerformanceLoader = false;
        this.isPreppingUserPerformanceTotalLoader = false;
        if (errorData.status === "canceled") {
          this.isPreppingUserPerformanceLoader = true;
        }
      }
    },

    async getPreppingUnitsByBrandTable(
      params: requestParams,
      callback?: (data: TableRow[]) => void,
    ) {
      if (params.isDownload) {
        this.isPreppingUnitsByBrandTotalLoader = true;
      } else {
        this.isPreppingUnitsByBrandLoader = true;
      }

      try {
        const { data } = await getPreppingUnitsByBrandTableApi(params, {
          signal: tableController.signal,
        });

        if (params.isDownload) {
          callback && callback(data);
        } else {
          this.unitsByBrand.data = data;
        }

        this.isPreppingUnitsByBrandLoader = false;
        this.isPreppingUnitsByBrandTotalLoader = false;
      } catch (error: RequestError) {
        const errorData = JSON.parse(error?.message);
        this.isPreppingUnitsByBrandLoader = false;
        this.isPreppingUnitsByBrandTotalLoader = false;
        if (errorData.status === "canceled") {
          this.isPreppingUnitsByBrandLoader = true;
        }
      }
    },

    async getCollapseTable(
      params: requestParams,
      callback?: (
        data: TableRow[],
        headers: TableHeader[],
        customColumns: boolean,
      ) => void,
      byProduct?: boolean,
    ) {
      if (params.isDownload) {
        this.isPreppingCollapseTableTotalLoader = true;
      } else {
        this.isPreppingCollapseTableLoader = true;
      }

      try {
        let response: any | null = null;
        if (byProduct) {
          const { data } = await getPreppingByProductTableApi(params, {
            signal: tableController.signal,
          });

          response = data;
        } else {
          const { data } = await getPreppingByUserTableApi(params, {
            signal: tableController.signal,
          });

          response = data;
        }

        if (params.isDownload) {
          const headers = [
            {
              name: "User",
              value: "USER",
            },
            {
              name: "SKU",
              value: "SKU",
            },
            {
              name: "Complexity",
              value: "COMPLEXITY",
            },
            {
              name: "Total Units",
              value: "TOTAL_UNITS",
              isNumber: true,
            },
            {
              name: "Units Per Minute",
              value: "UNITS_PER_MINUTE",
              roundedToTwoDecimals: true,
            },
          ];
          callback && callback(response, headers, true);
        } else {
          this.expandTable.data = response?.data;
          this.expandTable.pagination = response?.pagination;
        }

        this.isPreppingCollapseTableLoader = false;
        this.isPreppingCollapseTableTotalLoader = false;
      } catch (error: RequestError) {
        const errorData = JSON.parse(error?.message);
        this.isPreppingCollapseTableLoader = false;
        this.isPreppingCollapseTableTotalLoader = false;
        if (errorData.status === "canceled") {
          this.isPreppingCollapseTableLoader = true;
        }
      }
    },
  },
  getters: {
    totalCases(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`TOTAL_CASES_DIFF`] || "",
            ratio_percentage: this.PoPKpiData?.[`TOTAL_CASES_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`TOTAL_CASES_DIFF`] || "",
            ratio_percentage: this.WoWKpiData?.[`TOTAL_CASES_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`TOTAL_CASES_DIFF`] || "",
            ratio_percentage: this.MoMKpiData?.[`TOTAL_CASES_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`TOTAL_CASES_DIFF`] || "",
            ratio_percentage: this.YoYKpiData?.[`TOTAL_CASES_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "Total Units",
        values: values,
      };
    },
    totalUnits(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`TOTAL_UNITS_DIFF`] || "",
            ratio_percentage: this.PoPKpiData?.[`TOTAL_UNITS_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`TOTAL_UNITS_DIFF`] || "",
            ratio_percentage: this.WoWKpiData?.[`TOTAL_UNITS_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`TOTAL_UNITS_DIFF`] || "",
            ratio_percentage: this.MoMKpiData?.[`TOTAL_UNITS_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`TOTAL_UNITS_DIFF`] || "",
            ratio_percentage: this.YoYKpiData?.[`TOTAL_UNITS_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "Total Shipments",
        values: values,
      };
    },
    totalUnitsPerMinute(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`TOTAL_UNITS_PER_MINUTE_DIFF`] || "",
            ratio_percentage:
              this.PoPKpiData?.[`TOTAL_UNITS_PER_MINUTE_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`TOTAL_UNITS_PER_MINUTE_DIFF`] || "",
            ratio_percentage:
              this.WoWKpiData?.[`TOTAL_UNITS_PER_MINUTE_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`TOTAL_UNITS_PER_MINUTE_DIFF`] || "",
            ratio_percentage:
              this.MoMKpiData?.[`TOTAL_UNITS_PER_MINUTE_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`TOTAL_UNITS_PER_MINUTE_DIFF`] || "",
            ratio_percentage:
              this.YoYKpiData?.[`TOTAL_UNITS_PER_MINUTE_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "Units Per Shipment",
        values: values,
      };
    },
    netTimeInMinutes(): IKpisFormat {
      const values = [
        {
          name: "PoP",
          value: {
            difference: this.PoPKpiData?.[`NET_TIME_IN_MINUTES_DIFF`] || "",
            ratio_percentage:
              this.PoPKpiData?.[`NET_TIME_IN_MINUTES_PERCENT`] || "",
          },
        },
        {
          name: "WoW",
          value: {
            difference: this.WoWKpiData?.[`NET_TIME_IN_MINUTES_DIFF`] || "",
            ratio_percentage:
              this.WoWKpiData?.[`NET_TIME_IN_MINUTES_PERCENT`] || "",
          },
        },
        {
          name: "MoM",
          value: {
            difference: this.MoMKpiData?.[`NET_TIME_IN_MINUTES_DIFF`] || "",
            ratio_percentage:
              this.MoMKpiData?.[`NET_TIME_IN_MINUTES_PERCENT`] || "",
          },
        },
        {
          name: "YoY",
          value: {
            difference: this.YoYKpiData?.[`NET_TIME_IN_MINUTES_DIFF`] || "",
            ratio_percentage:
              this.YoYKpiData?.[`NET_TIME_IN_MINUTES_PERCENT`] || "",
          },
        },
      ];

      return {
        name: "ltl Percentage",
        values: values,
      };
    },
  },
});
