<script setup lang="ts">
import { computed, ref } from "vue";

import MenuItem from "./MenuItem.vue";
import { coreRoutes } from "@/router/core";
import { useAuthStore } from "@/store/auth";
import { PermissionsType } from "@/types/router";
import { checkInsightful } from "@/utils/permissions";

import SmallLogo from "@/assets/images/pages/SmallLogo.vue";
import LogoImage from "@/assets/svg/LogoImage.vue";

const authStore = useAuthStore();

withDefaults(
  defineProps<{
    isOpen: boolean;
    isSmallMenu: boolean;
  }>(),
  {
    isOpen: false,
    isSmallMenu: false,
  },
);
const emit = defineEmits(["showMenu", "handleSmallMenu"]);

const menuToggleHandler = ref<boolean>(true);

const canAccess = (permission?: PermissionsType) => {
  if (authStore.user?.role === "admin") return true;
  return permission && authStore.user?.permissions?.includes(permission);
};

const menuList = computed(() => {
  const routes = coreRoutes.filter((route) => {
    if (
      checkInsightful(authStore.user?.permissions as any) &&
      route.path === "/insightful"
    ) {
      return route;
    } else {
      return canAccess(route.meta?.permission as PermissionsType);
    }
  });
  return routes;
});

const toggleMenu = (showMenu: boolean) => {
  menuToggleHandler.value = showMenu;
  emit("showMenu", showMenu);
};
</script>

<template>
  <nav
    class="menu"
    :class="{
      'small-menu': isSmallMenu,
    }"
    @mouseover="toggleMenu(false)"
    @mouseleave="toggleMenu(true)"
  >
    <section
      class="mt-[10px] h-[60px] p-2 flex justify-center items-center rounded-md"
    >
      <div class="w-full flex justify-center items-center">
        <LogoImage class="max-w-[90%]" :class="{ hidden: isSmallMenu }" />

        <SmallLogo :class="{ hidden: !isSmallMenu }" />
      </div>
    </section>
    <section class="overflow-y-auto overflow-x-hidden">
      <MenuItem
        v-for="(item, index) in menuList"
        :key="index"
        :routeItem="item"
        :depth="0"
        :smallMenu="isSmallMenu"
        :isOpen="menuToggleHandler"
      />
    </section>
  </nav>
</template>

<style lang="scss" scoped>
.menu {
  @apply shadow-xl dark:shadow-slate-900/50 bg-navigationBg text-secondaryText fixed top-0 transition-all duration-300 z-[101] flex flex-col gap-2 ml-2 mt-3 mb-2 rounded-lg;
  position: fixed;
  height: calc(100vh - 30px);
  width: 240px;

  &.small-menu {
    overflow: inherit;
    width: 100px;
  }
}
</style>
