import { DateTime } from "luxon";
import { defineStore } from "pinia";

import type { TableRow } from "@/types/table.ts";
import { useFiltersStore } from "@/store/filters";
import type { RequestError, requestParams } from "@/types/requests.ts";
import { ICycleTimeStore } from "@/types/warehouse/cycleTime.ts";
import {
  getAbortSignal,
  replaceController,
  replaceSingleController,
} from "@/utils/createAbortControllers.ts";
import {
  getCycleByBrandTableApi,
  getCycleByProductTableApi,
  getCycleChartApi,
} from "@/api/warehouse.ts";

const controllers: AbortController[] = [];
let tableController = new AbortController();

export const useCycleTimeStore = defineStore("cycleTime", {
  state: (): ICycleTimeStore => ({
    cycleChart: [],
    cycleTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    isCycleChartLoader: true,
    isCycleTableLoader: true,
    isCycleTableTotalLoader: true,
  }),
  actions: {
    abortControllersStore() {
      controllers.forEach((controller: AbortController, index: number) => {
        replaceController(index, controllers);
      });
    },

    abortTableRequest() {
      tableController = replaceSingleController(tableController);
    },

    async getCycleChart(params: requestParams) {
      const filterStore = useFiltersStore();
      this.isCycleChartLoader = true;
      try {
        const { data } = await getCycleChartApi(params, {
          signal: getAbortSignal(controllers),
        });

        this.cycleChart = data.sort((a, b) => {
          if (filterStore.granularity === "MONTH") {
            return (
              DateTime.fromFormat(a.DATE, "yyyy-dd-MM").toMillis() -
              DateTime.fromFormat(b.DATE, "yyyy-dd-MM").toMillis()
            );
          } else {
            return (
              DateTime.fromISO(a.DATE).toMillis() -
              DateTime.fromISO(b.DATE).toMillis()
            );
          }
        });
      } catch (e) {
        // eslint-disable-next-line no-console
      } finally {
        this.isCycleChartLoader = false;
      }
    },

    async getCycleTable(
      params: requestParams,
      callback?: (data: TableRow[]) => void,
      byProduct?: boolean,
    ) {
      if (params.isDownload) {
        this.isCycleTableTotalLoader = true;
      } else {
        this.isCycleTableLoader = true;
      }

      let response = null;

      try {
        if (byProduct) {
          const { data } = await getCycleByProductTableApi(params, {
            signal: tableController.signal,
          });
          response = data;
        } else {
          const { data } = await getCycleByBrandTableApi(params, {
            signal: tableController.signal,
          });
          response = data;
        }

        if (params.isDownload) {
          callback && callback(response.data);
        } else {
          this.cycleTable.data = response.data;
          this.cycleTable.pagination = response.pagination;
        }

        this.isCycleTableLoader = false;
        this.isCycleTableTotalLoader = false;
      } catch (error: RequestError) {
        const errorData = JSON.parse(error?.message);
        this.isCycleTableLoader = false;
        this.isCycleTableTotalLoader = false;
        if (errorData.status === "canceled") {
          this.isCycleTableLoader = true;
        }
      }
    },
  },
});
