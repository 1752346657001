import { DateTime, Interval } from "luxon";

export const parseDateUtils = (
  start: string,
  end: string,
  currentGranylarityItem: "MONTH" | "WEEK",
  formattedDatesArray: { value: string[] },
) => {
  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);

  const interval = Interval.fromDateTimes(startDate, endDate);

  if (currentGranylarityItem === "MONTH") {
    formattedDatesArray.value = interval
      .splitBy({ months: 1 })
      .map((dt: any) => dt.start.startOf("month").toFormat("LLL yyyy"));

    const lastMonth = DateTime.fromISO(end).startOf("month");
    if (!formattedDatesArray.value.includes(lastMonth.toFormat("LLL yyyy"))) {
      formattedDatesArray.value.push(lastMonth.toFormat("LLL yyyy"));
    }

    formattedDatesArray.value.sort(
      (a: string, b: string) =>
        DateTime.fromFormat(a, "LLL yyyy").toMillis() -
        DateTime.fromFormat(b, "LLL yyyy").toMillis(),
    );
  } else if (currentGranylarityItem === "WEEK") {
    formattedDatesArray.value = [];
    let cursor = interval.start?.startOf("day");
    if (cursor && cursor?.weekday !== 7) {
      cursor = cursor!.plus({ days: 7 - cursor.weekday });
    }
    while (interval.contains(cursor as any)) {
      formattedDatesArray.value.push(cursor!.toFormat("MM/dd/yyyy"));
      cursor = cursor!.plus({ weeks: 1 });
    }
  }

  return formattedDatesArray.value;
};

const convertToISODate = (
  date: string,
  granularity: "MONTH" | "WEEK" | "DAY",
): string | null => {
  try {
    return DateTime.fromFormat(
      date,
      granularity === "DAY" ? "MM/dd/yyyy" : "MMM yyyy",
    ).toISODate();
  } catch {
    return null;
  }
};

export const transformDateArray = (dateArray: string[]): string[] => {
  const uniqueMonths = new Set<string>();
  dateArray.forEach((date) => {
    const dt = DateTime.fromFormat(date, "MM/dd/yyyy", { zone: "utc" });
    const monthYear = dt.toFormat("MMM yyyy");
    uniqueMonths.add(monthYear);
  });
  return Array.from(uniqueMonths).sort((a, b) => {
    const [monthA, yearA] = a.split(" ");
    const [monthB, yearB] = b.split(" ");
    return yearA.localeCompare(yearB) || monthA.localeCompare(monthB);
  });
};

const isMonthFormat = (dates: string[]): boolean => {
  return dates.every((date) => /^([A-Za-z]+ \d{4})$/.test(date));
};

const isWeekFormat = (dates: string[]): boolean => {
  return dates.every((date) => /^\d{2}\/\d{2}\/\d{4}$/.test(date));
};

export const changingMonthToWeekListForTable = (
  currentDate: [string, string],
  periodValuesT: string[],
  granularity: "MONTH" | "WEEK" | "DAY",
  summary?: boolean,
): { name: string; value: string }[] => {
  const selectOptions: { name: string; value: string }[] = [];

  let periodValues = periodValuesT;
  if (granularity === "WEEK") {
    if (isMonthFormat(periodValuesT)) {
      periodValues = periodValuesT;
    } else if (isWeekFormat(periodValuesT)) {
      periodValues = transformDateArray(periodValuesT);
    }
  } else if (granularity === "MONTH") {
    if (isWeekFormat(periodValuesT)) {
      periodValues = transformDateArray(periodValuesT);
    } else if (!isMonthFormat(periodValuesT)) {
      periodValues = transformDateArray(periodValuesT);
    }
  }

  if (currentDate) {
    const defaultObject = summary
      ? [
          {
            name:
              granularity === "MONTH"
                ? "All Months"
                : granularity === "WEEK"
                  ? "All Weeks"
                  : "All Days",
            value: "all",
          },
          {
            name: "Summary",
            value: "summary",
          },
        ]
      : [
          {
            name:
              granularity === "MONTH"
                ? "All Months"
                : granularity === "WEEK"
                  ? "All Weeks"
                  : "All Days",
            value: "all",
          },
        ];
    selectOptions.unshift(...defaultObject);

    const uniqueDates = [...(new Set(periodValues) as any)];

    const options = uniqueDates.flatMap((date: any) => {
      try {
        const isoDate = convertToISODate(date, granularity);
        if (!isoDate)
          throw new Error(`Invalid ISO date conversion for: ${date}`);

        let dt: DateTime;
        if (granularity === "MONTH") {
          dt = DateTime.fromISO(isoDate);
          return [
            {
              name: dt.toFormat("MMMM yyyy"),
              value: dt.startOf("month").toISODate(),
            },
          ];
        } else if (granularity === "WEEK") {
          dt = DateTime.fromISO(isoDate);
          const monthStart = dt.startOf("month");
          const monthEnd = dt.endOf("month");
          let currentWeekStart = monthStart.startOf("week").plus({ days: 6 });
          const weekEndDates: string[] = [];

          while (currentWeekStart <= monthEnd) {
            weekEndDates.push(currentWeekStart.toISODate() as any);
            currentWeekStart = currentWeekStart.plus({ weeks: 1 });
          }

          const weeks = weekEndDates
            .map((weekEndDate) => {
              const endOfWeek = DateTime.fromISO(weekEndDate);
              if (endOfWeek >= DateTime.local().startOf("day")) {
                return null;
              }

              return {
                name: endOfWeek.toFormat("MM/dd/yyyy"),
                value: endOfWeek.toISODate(),
              };
            })
            .filter((opt) => opt !== null) as { name: string; value: string }[];

          return weeks;
        } else if (granularity === "DAY") {
          return [];
        }
      } catch (error) {
        return [];
      }
    });

    selectOptions.push(...(options.flat() as any));
  }

  const cleanedSelectOptions = Array.from(
    new Map(selectOptions.map((item) => [item.value, item])).values(),
  ).sort((a, b) => new Date(b.value).getTime() - new Date(a.value).getTime());

  return cleanedSelectOptions;
};

export const formattigDateForRequestParams = (
  selectOption: string,
  granularity: "MONTH" | "WEEK" | "DAY",
) => {
  if (selectOption === "summary") return {};

  if (selectOption === "last30days") {
    return {
      period: {
        since: DateTime.now().minus({ days: 30 }),
        until: DateTime.now(),
      },
    };
  }

  if (selectOption === "last60days") {
    return {
      period: {
        since: DateTime.now().minus({ days: 60 }),
        until: DateTime.now(),
      },
    };
  }
  if (selectOption === "last90days") {
    return {
      period: {
        since: DateTime.now().minus({ days: 90 }),
        until: DateTime.now(),
      },
    };
  }
  if (selectOption === "lastyear") {
    return {
      period: {
        since: DateTime.now().minus({ days: 365 }),
        until: DateTime.now(),
      },
    };
  }
  if (selectOption !== "all") {
    if (granularity === "MONTH") {
      const initialDate = DateTime.fromISO(selectOption);
      const oneMonthLater = initialDate.endOf("month");
      DateTime.fromFormat(selectOption, "yyyy-MM-dd").toFormat("LLL yyyy");
      return {
        period: {
          since: selectOption,
          until: oneMonthLater.toISODate(),
        },
      };
    } else {
      return {
        period: {
          since: DateTime.fromFormat(selectOption, "yyyy-MM-dd"),
          until: DateTime.fromFormat(selectOption, "yyyy-MM-dd").plus({
            days: 6,
          }),
        },
      };
    }
  } else return {};
};
