<script setup lang="ts">
import { ref, onUnmounted, onMounted, nextTick } from "vue";

import { useFiltersStore } from "@/store/filters";

import GlobalLoader from "./GlobalLoader.vue";
import AppHeader from "@/components/core/AppHeader/AppHeader.vue";
import AppNavSidebar from "@/components/core/NavBars/AppNavSidebar.vue";
import FiltersBar from "@/components/core/Filters/FiltersBar.vue";
import FilterSidebar from "@/components/core/Filters/FilterSidebar.vue";

const filterStore = useFiltersStore();

const miniMenu = ref(true);
const isShowMenu = ref(false);
const isFixed = ref(false);
const initialTopOffset = ref(0);

/*
 * Show or hide the sidebar
 * @param {boolean} smallMenu
 */
const showMenu = (smallMenu: boolean) => {
  miniMenu.value = smallMenu;
};

/*
 * Handle the small menu
 * @param {boolean} smallMenu
 */
const handleSmallMenu = (smallMenu: boolean) => {
  miniMenu.value = smallMenu;
};

const handleScroll = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  initialTopOffset.value = scrollTop;
  if (scrollTop >= 130) {
    isFixed.value = true;
  } else {
    isFixed.value = false;
  }
};

onMounted(async () => {
  await nextTick();
  await filterStore.getUserFilters();
  const filtersBar = document.querySelector(".filters-bar") as HTMLElement;
  if (filtersBar) {
    initialTopOffset.value =
      filtersBar.getBoundingClientRect().top + window.pageYOffset - 100;
  }

  window.addEventListener("scroll", handleScroll, { passive: true });
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<template>
  <section>
    <AppHeader class="ml-[100px]" />
    <main class="min-h-[calc(100vh-124px)] flex transition-all duration-500">
      <AppNavSidebar
        @show-menu="showMenu"
        :isOpen="isShowMenu"
        :isSmallMenu="miniMenu"
        @handleSmallMenu="handleSmallMenu"
      />

      <section
        v-if="!filterStore.loading"
        class="p-3 pt-0 w-full"
        :style="{
          'margin-left': '100px',
        }"
        :class="{ 'mt-[100px]': isFixed }"
      >
        <div
          :class="{
            'fixed top-0 right-0 shadow-md bg-mainBg dark:shadow-slate-900/40':
              isFixed,
            flex: !isFixed,
          }"
          class="filters-bar pb-3 z-[99] transition-all duration-500"
          :style="{
            width: '100%',
            'max-width': isFixed ? 'calc(100% - 100px)' : '100%',
          }"
        >
          <FiltersBar />
        </div>
        <div>
          <router-view />
        </div>
      </section>
      <section v-else class="w-full h-[calc(100vh-200px)]">
        <GlobalLoader height="calc(100vh - 200px)" />
      </section>
      <FilterSidebar :isFixed="isFixed" />
    </main>
  </section>
</template>

<style scoped>
.filters-bar.fixed {
  transition:
    top 0.5s,
    width 0.5s;
}
.filters-bar {
  transition: width 0.5s;
}
</style>
