import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { ACCESS_TOKEN } from "@/utils/axios.ts";
import {
  ArrayOfPriorityRoutes,
  PermissionsType,
  RouteNames,
  RoutePathsWithPermissions,
} from "@/types/router.ts";
import { checkInsightful, hasPermission } from "@/utils/permissions.ts";

export const handleNavigation = (
  from: RouteLocationNormalized,
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
  authStore: any,
) => {
  redirectTo(from, to, next, authStore);
  redirectToNotFound(to, next);
  handleRouting(from, to, next, authStore);
};

export const redirectTo = (
  from: RouteLocationNormalized,
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
  authStore: any,
) => {
  redirectToLoginPage(from, to, next);
  redirectToDontHavePermissionOnLogin(from, to, next, authStore);
};

export const handleRouting = (
  from: RouteLocationNormalized,
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
  authStore: any,
) => {
  if (authStore?.user?.role === "admin") return next();
  if (
    hasPermission(
      authStore.user?.permissions as string[],
      to.meta?.permission as any,
    ) ||
    !to.name
  ) {
    if (to.path === "/insightful") {
      if (checkInsightful(authStore.user?.permissions as string[])) {
        return next();
      } else {
        const firstFindedPermission = ArrayOfPriorityRoutes.find((route) => {
          return authStore.user?.permissions?.find((permission: any) => {
            if (permission === route.permission) return route;
          });
        });
        if (!firstFindedPermission?.path)
          return next({ name: RouteNames.DONT_HAVE_PERMISSION });
        return next({
          path: RoutePathsWithPermissions[
            firstFindedPermission?.permission as PermissionsType
          ],
        });
      }
    }

    if (
      !hasPermission(
        authStore.user?.permissions as string[],
        to.meta?.permission as any,
      )
    ) {
      if (to.name === "DontHavePermission") {
        return next();
      }
      return next();
    } else {
      if (to.name !== "DontHavePermission") {
        return next({ name: "DontHavePermission" });
      } else {
        return next();
      }
    }
  } else {
    next();
  }
};

const redirectToDontHavePermissionOnLogin = (
  from: RouteLocationNormalized,
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
  authStore: any,
) => {
  if (
    from.path === "/login" &&
    authStore.user?.role !== "admin" &&
    !authStore.user?.permissions?.length &&
    to.path === "/login"
  ) {
    return next({ name: "DontHavePermission" });
  }
};

const redirectToLoginPage = (
  from: RouteLocationNormalized,
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  if (!to.meta.withoutAuth && !accessToken) {
    return next({ name: "LoginPage" });
  }
};

const redirectToNotFound = (
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (to.name === RouteNames.NOT_FOUND) {
    return next();
  }
};
