import { defineStore } from "pinia";

import type { TableRow } from "@/types/table";
import {
  getKpiAdsAwareness as getKpiAdsAwarenessApi,
  getChartAdsAwareness as getChartAdsAwarenessApi,
  getKpiTotalAdsAwareness as getKpiTotalAdsAwarenessApi,
  getAdsAwarenessTable as getAdsAwarenessTableApi,
} from "@/api/adsSummary";
import type { RequestError, requestParams } from "@/types/requests";
import type { AwarenessStore } from "@/types/adsSummary/adsAwareness";
import {
  getAbortSignal,
  replaceController,
  replaceSingleController,
} from "@/utils/createAbortControllers";

const controllers: AbortController[] = [];
let tableController = new AbortController();

export const useAdsSummaryAwarenessStore = defineStore("adsSummaryAwareness", {
  state: (): AwarenessStore => ({
    chartAdsAwareness: [],
    adsAwarenesseTable: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    adsAwarenessTotalValues: null,
    adsAwarenesseKpiValues: null,
    WoWKpiData: null,
    MoMKpiData: null,
    YoYKpiData: null,
    PoPKpiData: null,
    isAdsAwarenessLoader: true,
    isAdsAwarenessKpiLoader: true,
    isAdsAwarenessChartLoader: true,
    isAdsAwarenessTableLoader: true,
    isAdsAwarenessTableTotalLoader: true,
  }),

  actions: {
    abortRequestsAwarenessStore() {
      this.cleanStore();
      controllers.forEach((controller: AbortController, index: number) => {
        replaceController(index, controllers);
      });
    },

    abortTableRequest() {
      tableController = replaceSingleController(tableController);
    },

    getChartAdsAwareness(params: requestParams) {
      this.isAdsAwarenessLoader = true;
      this.isAdsAwarenessChartLoader = true;
      getChartAdsAwarenessApi(params, {
        signal: getAbortSignal(controllers),
      })
        .then(({ data }) => {
          this.chartAdsAwareness = data;
        })
        .catch((e) => {})
        .finally(() => {
          this.isAdsAwarenessLoader = false;
          this.isAdsAwarenessChartLoader = false;
        });
    },

    getKpiAdsAwareness(
      params: requestParams,
      type?: "WoWKpiData" | "MoMKpiData" | "YoYKpiData" | "PoPKpiData",
    ) {
      return getKpiAdsAwarenessApi(params, {
        signal: getAbortSignal(controllers),
      })
        .then(({ data }) => {
          if (type) {
            this[type] = null;
            this[type] = data;
          } else {
            return (this.adsAwarenesseKpiValues = data);
          }
        })
        .catch((e) => {})
        .finally(() => {});
    },

    getKpiTotalAdsAwareness(params: requestParams) {
      return getKpiTotalAdsAwarenessApi(params, {
        signal: getAbortSignal(controllers),
      })
        .then(({ data }) => {
          this.adsAwarenessTotalValues = data;
        })
        .catch((e) => {});
    },

    updateKpisLoader(value: boolean) {
      this.isAdsAwarenessKpiLoader = value;
    },

    getAdsAwarenessTable(
      params: requestParams,
      callback?: (data: TableRow[]) => void,
    ) {
      if (params.limit === 0) {
        this.isAdsAwarenessTableTotalLoader = true;
      } else {
        this.isAdsAwarenessTableLoader = true;
      }
      getAdsAwarenessTableApi(params, {
        signal: tableController.signal,
      })
        .then(({ data }) => {
          if (params.limit === 0) {
            callback && callback(data.data);
          } else {
            this.adsAwarenesseTable.data = data.data;
            this.adsAwarenesseTable.pagination = data.pagination;
          }
          this.isAdsAwarenessTableLoader = false;
          this.isAdsAwarenessTableTotalLoader = false;
        })
        .catch((err: RequestError) => {
          const errorData = JSON.parse(err?.message);
          this.isAdsAwarenessTableLoader = false;
          this.isAdsAwarenessTableTotalLoader = false;
          if (errorData.status === "canceled") {
            this.isAdsAwarenessTableLoader = true;
          }
        });
    },

    cleanStore() {
      this.adsAwarenesseTable = {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      };
      this.adsAwarenessTotalValues = null;
      this.adsAwarenesseKpiValues = null;
      this.WoWKpiData = null;
      this.MoMKpiData = null;
      this.YoYKpiData = null;
      this.PoPKpiData = null;
      this.isAdsAwarenessLoader = true;
      this.isAdsAwarenessKpiLoader = true;
      this.isAdsAwarenessChartLoader = true;
      this.isAdsAwarenessTableLoader = true;
      this.isAdsAwarenessTableTotalLoader = true;
    },
  },
  getters: {
    impressionsKpiValue() {
      const values: {
        name: string;
        value: { difference: number; ratio_percentage: number } | undefined;
      }[] = [
        { name: "PoP", value: this.PoPKpiData?.Impressions },
        { name: "WoW", value: this.WoWKpiData?.Impressions },
        { name: "MoM", value: this.MoMKpiData?.Impressions },
        { name: "YoY", value: this.YoYKpiData?.Impressions },
      ];
      return {
        name: "Impressions",
        values: values,
      };
    },
    clicksKpiValue() {
      const values: {
        name: string;
        value: { difference: number; ratio_percentage: number } | undefined;
      }[] = [
        { name: "PoP", value: this.PoPKpiData?.Clicks },
        { name: "WoW", value: this.WoWKpiData?.Clicks },
        { name: "MoM", value: this.MoMKpiData?.Clicks },
        { name: "YoY", value: this.YoYKpiData?.Clicks },
      ];
      return {
        name: "Clicks",
        values: values,
      };
    },
    ordersKpiValue() {
      const values: {
        name: string;
        value: { difference: number; ratio_percentage: number } | undefined;
      }[] = [
        { name: "PoP", value: this.PoPKpiData?.Orders },
        { name: "WoW", value: this.WoWKpiData?.Orders },
        { name: "MoM", value: this.MoMKpiData?.Orders },
        { name: "YoY", value: this.YoYKpiData?.Orders },
      ];
      return {
        name: "Orders",
        values: values,
      };
    },
  },
});
