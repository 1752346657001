<script setup lang="ts">
import { ref } from "vue";
import { vOnClickOutside } from "@vueuse/components";

const props = withDefaults(
  defineProps<{
    marginFromTop?: string;
    starterPosition?: string;
    width?: string;
  }>(),
  {
    marginFromTop: "70px",
    starterPosition: "120px",
    width: "200px",
  },
);

const showMenu = ref(false);

const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};

const closeMenu = () => {
  showMenu.value = false;
};
</script>

<template>
  <section class="menu-wrapper" v-on-click-outside="closeMenu">
    <div
      @click="toggleMenu"
      class="flex justify-center items-center cursor-pointer w-full"
    >
      <slot name="button" />
    </div>
    <div
      class="menu-items-wrapper"
      :class="{
        'menu-items-wrapper-closed': !showMenu,
        'menu-items-wrapper-open': showMenu,
      }"
      :style="{
        top: showMenu ? props.marginFromTop : props.starterPosition,
        'min-width': props.width,
      }"
    >
      <slot name="items" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.menu-wrapper {
  @apply relative h-full w-full flex justify-center items-center;
}

.menu-items-wrapper {
  @apply absolute p-4 rounded-md right-0 transition-all duration-500 flex flex-col gap-3 z-[100] shadow-lg dark:shadow-slate-900/50;
}

.menu-items-wrapper-closed {
  @apply top-[100px] opacity-0 z-[-100];
}

.menu-items-wrapper-open {
  @apply opacity-100 z-[50] bg-cardBg;
}
</style>
