<script setup lang="ts">
import { computed } from "vue";

import { defaultTelePort, type TeleportType } from "@/utils/modal";

import BaseCard from "@/components/common/CommonCard.vue";

type Props = {
  /**
   * @param {string} id - The id of the modal. If not provided, the "default" will be used as id". By registering this ID, you can open the modal from anywhere in the app.
   */
  teleport: TeleportType;
  /**
   * @param {string} title - The title to be displayed in the modal header. Can be overwritten using the slot with id #title
   */
  title?: string;
  /**
   * @param {string} buttonText - Text to be displayed on the submit button
   */
  buttonText?: string;
  /**
   * @param {boolean} canSubmit - Boolean which controls whether or not the submit button is displayed
   */
  canSubmit?: boolean;
  /**
   * @param {boolean} canSubmit - Boolean which controls whether or not the submit button is displayed
   */
  shouldBeModalClosedOnSubmit?: boolean;
  /**
   * @param {string} customHeight - The height of the modal. Default is 300px
   */
  customHeight?: string;
  /**
   * @param {boolean} disabled - Boolean which controls whether or not the submit button is disabled
   */
  disabled?: boolean;
  /**
   * @param {string} width - The width of the modal. Default is 300px
   */
  width?: string;
  /**
   * @param {string} maxWidth - The max width of the modal. Default is 300px
   */
  maxWidth?: string;
  /**
   * @param {string} text - The text to be displayed on the modal content
   */
  text?: string;
};

const props = withDefaults(defineProps<Props>(), {
  teleport: () => defaultTelePort,
  canSubmit: true,
  customHeight: "300",
  buttonText: "Submit",
  width: "100%",
  maxWidth: "300px",
});

const emit = defineEmits<{
  /**
   * @event submit - Emitted when the submit button is clicked
   */
  (event: "submit"): void;
  /**
   * @event submit - Emitted when the close button is clicked
   */
  (event: "close"): void;
}>();

const isOpen = computed(() => {
  return props.teleport.isOpen;
});

const onSubmit = () => {
  emit("submit");

  if (!props.shouldBeModalClosedOnSubmit) {
    props.teleport.close();
  }
};

const onClose = () => {
  emit("close");
  props.teleport.close();
};
</script>

<template>
  <Teleport to="#modal">
    <!--BACKGROUND OVERLAY-->
    <div
      class="fixed top-0 w-[100vw] h-full bg-black bg-opacity-30 z-[51] flex justify-center items-center"
      :class="{ hidden: !isOpen }"
      v-bind="$attrs"
    >
      <!--MODAL-->
      <BaseCard :style="{ width: props.width, 'max-width': props.maxWidth }">
        <!--MODAL HEADER-->
        <div class="p-3">
          <slot name="header" />
          <div class="flex justify-between">
            <span
              v-if="!$slots.header"
              class="text-mainText font-normal text-[16px] capitalize"
            >
              {{ title }}
            </span>
            <feather-icon type="x" class="hover-text" @click="onClose" />
          </div>
        </div>

        <!--MODAL CONTENT-->
        <section
          class="overflow-auto w-full py-5 px-3"
          :style="{ 'max-height': props.customHeight + 'px' }"
        >
          <span class="block">
            {{ text }}
          </span>
          <slot name="default" />
        </section>

        <!--MODAL BUTTONS-->
        <div class="flex justify-between p-3">
          <button @click="onClose" class="hover-text">Cancel</button>
          <slot name="buttons" />
          <button
            v-if="canSubmit && !$slots.buttons"
            @click="onSubmit"
            class="flex gap-2 items-center px-5 py-2 text-sm w-fit text-white rounded-md bg-primary cursor-pointer shadow-md hover:shadow-lg hover:bg-[#0E61D5] transition-all duration-500"
            :disabled="props.disabled"
          >
            {{ buttonText }}
          </button>
        </div>
      </BaseCard>
    </div>
  </Teleport>
</template>

<style scoped>
.hover-text {
  @apply cursor-pointer transition-all duration-500 hover:text-primary;
}
</style>
