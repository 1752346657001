import { defineStore } from "pinia";

import type { RequestError, requestParams } from "@/types/requests";
import {
  getAbortSignal,
  replaceController,
  replaceSingleController,
} from "@/utils/createAbortControllers";
import type {
  AccountHealthMessagesStore,
  Chart,
} from "@/types/accountHealth/messages";
import { capitalizeFirstLetterOfEachWord } from "@/utils/reusableFunctions";

import {
  getAccountHealthMessagesKpiApi,
  getAccountHealthMessagesKpiTotalApi,
  getAccountHealthMessagesChartApi,
  getAccountHealthMessagesTableApi,
  getAccountHealthErrorsChartApi,
} from "@/api/account-health";
import type { TableRow } from "@/types/table";
import { IKpisFormat } from "@/types/Kpi.ts";

const controllers: AbortController[] = [];
let errorTableController = new AbortController();
let resolvedTableController = new AbortController();

export const useAccountHealthMessagesStore = defineStore(
  "accountHealthMessages",
  {
    state: (): AccountHealthMessagesStore => ({
      kpiTotal: null,
      WoWKpiData: null,
      MoMKpiData: null,
      YoYKpiData: null,
      PoPKpiData: null,
      productMessagesChart: [],
      kpiLoader: true,
      isChartLoader: true,
      isResolvedTableLoader: true,
      isResolvedTableTotalsLoader: true,
      resolvedTable: {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      },
      isErrorTableLoader: true,
      isErrorTableTotalsLoader: true,
      errorTable: {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      },

      errorsChart: [],
      errorsChartLoader: true,
    }),

    actions: {
      abortRequestsAccountHealthMessagesStore() {
        controllers.forEach((controller: AbortController, index: number) => {
          replaceController(index, controllers);
        });
      },

      abortErrorTable() {
        errorTableController = replaceSingleController(errorTableController);
      },
      abortResolvedTable() {
        resolvedTableController = replaceSingleController(
          resolvedTableController,
        );
      },

      updateKpiLoader(value: boolean) {
        this.kpiLoader = value;
      },

      async getAccountHealthMessagesKpi(
        params: requestParams,
        type: "WoWKpiData" | "MoMKpiData" | "YoYKpiData" | "PoPKpiData",
      ) {
        try {
          const { data } = await getAccountHealthMessagesKpiApi(params, {
            signal: getAbortSignal(controllers),
          });
          this[type] = null;
          this[type] = data;
        } catch (e) {
          /* empty */
        }
      },
      async getAccountHealthMessagesKpiTotal(params: requestParams) {
        try {
          const { data } = await getAccountHealthMessagesKpiTotalApi(params, {
            signal: getAbortSignal(controllers),
          });

          this.kpiTotal = data;
        } catch (e) {
          /* empty */
        }
      },

      async getAccountHealthErrorsChart(params: requestParams) {
        this.errorsChartLoader = true;
        try {
          const { data } = await getAccountHealthErrorsChartApi(params, {
            signal: getAbortSignal(controllers),
          });
          this.errorsChart = data;
        } catch {
          /* empty */
        } finally {
          this.errorsChartLoader = false;
        }
      },

      async getAccountHealthMessagesChart(params: requestParams) {
        this.isChartLoader = true;
        try {
          const { data } = await getAccountHealthMessagesChartApi(params, {
            signal: getAbortSignal(controllers),
          });
          this.productMessagesChart = data.map((el: Chart) => {
            return {
              ...el,
              CATEGORY: capitalizeFirstLetterOfEachWord(el.CATEGORY),
            };
          });
        } catch {
          /* empty */
        } finally {
          this.isChartLoader = false;
        }
      },

      async getAccountHealthResolvedMessagesTable(
        params: requestParams,
        callback?: (data: TableRow[]) => void,
      ) {
        if (params.limit === 0) {
          this.isResolvedTableTotalsLoader = true;
        } else {
          this.isResolvedTableLoader = true;
        }
        try {
          const { data } = await getAccountHealthMessagesTableApi(params, {
            signal: resolvedTableController.signal,
          });
          if (params.limit === 0) {
            callback && callback(data.data);
          } else {
            this.resolvedTable.data = data.data;
            this.resolvedTable.pagination = data.pagination;
          }
          this.isResolvedTableLoader = false;
          this.isResolvedTableTotalsLoader = false;
        } catch (err: RequestError) {
          const errorData = JSON.parse(err?.message);
          this.isResolvedTableLoader = false;
          this.isResolvedTableTotalsLoader = false;
          if (errorData.status === "canceled") {
            this.isResolvedTableLoader = true;
          }
        }
      },

      async getAccountHealthErrorMessagesTable(
        params: requestParams,
        callback?: (data: TableRow[]) => void,
      ) {
        if (params.limit === 0) {
          this.isErrorTableTotalsLoader = true;
        } else {
          this.isErrorTableLoader = true;
        }
        try {
          const { data } = await getAccountHealthMessagesTableApi(params, {
            signal: errorTableController.signal,
          });
          if (params.limit === 0) {
            callback && callback(data.data);
          } else {
            this.errorTable.data = data.data;
            this.errorTable.pagination = data.pagination;
          }
          this.isErrorTableLoader = false;
          this.isErrorTableTotalsLoader = false;
        } catch (error: RequestError) {
          const errorData = JSON.parse(error?.message);
          this.isErrorTableLoader = false;
          this.isErrorTableTotalsLoader = false;
          if (errorData.status === "canceled") {
            this.isErrorTableLoader = true;
          }
        }
      },
    },

    getters: {
      errorMessages(): IKpisFormat {
        const values = [
          {
            name: "PoP",
            value: {
              difference: this.PoPKpiData?.[`ERROR_MESSAGES_DIF`] || "",
              ratio_percentage: this.PoPKpiData?.[`ERROR_MESSAGES_PERC`] || "",
            },
          },
          {
            name: "WoW",
            value: {
              difference: this.WoWKpiData?.[`ERROR_MESSAGES_DIF`] || "",
              ratio_percentage: this.WoWKpiData?.[`ERROR_MESSAGES_PERC`] || "",
            },
          },
          {
            name: "MoM",
            value: {
              difference: this.MoMKpiData?.[`ERROR_MESSAGES_DIF`] || "",
              ratio_percentage: this.MoMKpiData?.[`ERROR_MESSAGES_PERC`] || "",
            },
          },
          {
            name: "YoY",
            value: {
              difference: this.YoYKpiData?.[`ERROR_MESSAGES_DIF`] || "",
              ratio_percentage: this.YoYKpiData?.[`ERROR_MESSAGES_PERC`] || "",
            },
          },
        ];

        return {
          name: "Error Messages",
          values: values,
        };
      },

      warningMessages(): IKpisFormat {
        const values = [
          {
            name: "PoP",
            value: {
              difference: this.PoPKpiData?.[`WARNING_MESSAGES_DIF`] || "",
              ratio_percentage:
                this.PoPKpiData?.[`WARNING_MESSAGES_PERC`] || "",
            },
          },
          {
            name: "WoW",
            value: {
              difference: this.WoWKpiData?.[`WARNING_MESSAGES_DIF`] || "",
              ratio_percentage:
                this.WoWKpiData?.[`WARNING_MESSAGES_PERC`] || "",
            },
          },
          {
            name: "MoM",
            value: {
              difference: this.MoMKpiData?.[`WARNING_MESSAGES_DIF`] || "",
              ratio_percentage:
                this.MoMKpiData?.[`WARNING_MESSAGES_PERC`] || "",
            },
          },
          {
            name: "YoY",
            value: {
              difference: this.YoYKpiData?.[`WARNING_MESSAGES_DIF`] || "",
              ratio_percentage:
                this.YoYKpiData?.[`WARNING_MESSAGES_PERC`] || "",
            },
          },
        ];

        return {
          name: "Error Messages",
          values: values,
        };
      },

      resolvedMessages(): IKpisFormat {
        const values = [
          {
            name: "PoP",
            value: {
              difference: this.PoPKpiData?.[`RESOLVED_MESSAGES_DIF`] || "",
              ratio_percentage:
                this.PoPKpiData?.[`RESOLVED_MESSAGES_PERC`] || "",
            },
          },
          {
            name: "WoW",
            value: {
              difference: this.WoWKpiData?.[`RESOLVED_MESSAGES_DIF`] || "",
              ratio_percentage:
                this.WoWKpiData?.[`RESOLVED_MESSAGES_PERC`] || "",
            },
          },
          {
            name: "MoM",
            value: {
              difference: this.MoMKpiData?.[`RESOLVED_MESSAGES_DIF`] || "",
              ratio_percentage:
                this.MoMKpiData?.[`RESOLVED_MESSAGES_PERC`] || "",
            },
          },
          {
            name: "YoY",
            value: {
              difference: this.YoYKpiData?.[`RESOLVED_MESSAGES_DIF`] || "",
              ratio_percentage:
                this.YoYKpiData?.[`RESOLVED_MESSAGES_PERC`] || "",
            },
          },
        ];

        return {
          name: "Error Messages",
          values: values,
        };
      },

      searchSuppressed(): IKpisFormat {
        const values = [
          {
            name: "PoP",
            value: {
              difference: this.PoPKpiData?.[`SEARCH_SUPPRESSED_DIF`] || "",
              ratio_percentage:
                this.PoPKpiData?.[`SEARCH_SUPPRESSED_PERC`] || "",
            },
          },
          {
            name: "WoW",
            value: {
              difference: this.WoWKpiData?.[`SEARCH_SUPPRESSED_DIF`] || "",
              ratio_percentage:
                this.WoWKpiData?.[`SEARCH_SUPPRESSED_PERC`] || "",
            },
          },
          {
            name: "MoM",
            value: {
              difference: this.MoMKpiData?.[`SEARCH_SUPPRESSED_DIF`] || "",
              ratio_percentage:
                this.MoMKpiData?.[`SEARCH_SUPPRESSED_PERC`] || "",
            },
          },
          {
            name: "YoY",
            value: {
              difference: this.YoYKpiData?.[`SEARCH_SUPPRESSED_DIF`] || "",
              ratio_percentage:
                this.YoYKpiData?.[`SEARCH_SUPPRESSED_PERC`] || "",
            },
          },
        ];

        return {
          name: "Error Messages",
          values: values,
        };
      },

      listingSuppressed(): IKpisFormat {
        const values = [
          {
            name: "PoP",
            value: {
              difference: this.PoPKpiData?.[`LISTING_SUPPRESSED_DIF`] || "",
              ratio_percentage:
                this.PoPKpiData?.[`LISTING_SUPPRESSED_PERC`] || "",
            },
          },
          {
            name: "WoW",
            value: {
              difference: this.WoWKpiData?.[`LISTING_SUPPRESSED_DIF`] || "",
              ratio_percentage:
                this.WoWKpiData?.[`LISTING_SUPPRESSED_PERC`] || "",
            },
          },
          {
            name: "MoM",
            value: {
              difference: this.MoMKpiData?.[`LISTING_SUPPRESSED_DIF`] || "",
              ratio_percentage:
                this.MoMKpiData?.[`LISTING_SUPPRESSED_PERC`] || "",
            },
          },
          {
            name: "YoY",
            value: {
              difference: this.YoYKpiData?.[`LISTING_SUPPRESSED_DIF`] || "",
              ratio_percentage:
                this.YoYKpiData?.[`LISTING_SUPPRESSED_PERC`] || "",
            },
          },
        ];

        return {
          name: "Error Messages",
          values: values,
        };
      },

      catalogItemRemoved(): IKpisFormat {
        const values = [
          {
            name: "PoP",
            value: {
              difference: this.PoPKpiData?.[`CATALOG_ITEM_REMOVED_DIF`] || "",
              ratio_percentage:
                this.PoPKpiData?.[`CATALOG_ITEM_REMOVED_PERC`] || "",
            },
          },
          {
            name: "WoW",
            value: {
              difference: this.WoWKpiData?.[`CATALOG_ITEM_REMOVED_DIF`] || "",
              ratio_percentage:
                this.WoWKpiData?.[`CATALOG_ITEM_REMOVED_PERC`] || "",
            },
          },
          {
            name: "MoM",
            value: {
              difference: this.MoMKpiData?.[`CATALOG_ITEM_REMOVED_DIF`] || "",
              ratio_percentage:
                this.MoMKpiData?.[`CATALOG_ITEM_REMOVED_PERC`] || "",
            },
          },
          {
            name: "YoY",
            value: {
              difference: this.YoYKpiData?.[`CATALOG_ITEM_REMOVED_DIF`] || "",
              ratio_percentage:
                this.YoYKpiData?.[`CATALOG_ITEM_REMOVED_PERC`] || "",
            },
          },
        ];

        return {
          name: "Error Messages",
          values: values,
        };
      },
    },
  },
);
