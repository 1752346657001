<script lang="ts" setup>
import CommonLoader from "@/components/common/CommonLoader.vue";

const props = withDefaults(
  defineProps<{
    text: string;
    type?: "button" | "submit" | "reset";
    disabled?: boolean;
    loader?: boolean;
    customColor?: boolean;
    height?: string;
    properCase?: boolean;
  }>(),
  {
    type: "button",
    disabled: false,
    height: "40px",
    properCase: true,
  },
);
</script>

<template>
  <button
    :type="props.type"
    class="button"
    :class="{
      capitalize: props.properCase,
    }"
    :style="{ height: props.height }"
    :disabled="props.disabled || props.loader"
    v-bind="$attrs"
  >
    <span
      v-if="$slots.iconLeft || props.loader"
      class="px-2 flex items-center justify-center"
    >
      <CommonLoader v-if="props.loader" class="w-[20px]" />

      <slot v-else name="iconLeft" />
    </span>
    <div class="flex gap-[3px] justify-center items-center">
      <span>
        {{ props.text }}
      </span>
    </div>
    <span v-if="$slots.iconRight" class="pl-[12px]">
      <slot name="iconRight" />
    </span>
  </button>
</template>
